import React, { useState, useCallback, useEffect, useMemo } from 'react'

import { requestGraphql } from '../../components/api'
import Page from '../../components/layout/Page'
import {
  PAGE_TITLE,
  PAGE_DESCRIPTION,
  HEADLINE,
  SUBHEAD,
  SENT_HEADLINE,
  SENT_ERROR_SUBHEAD,
  SENT_ERROR_HEADLINE,
  SENT_SUBHEAD,
  IMAGE_ALT1,
  IMAGE_ALT2,
  REFRESH_BUTTON_TEXT,
  SEND_BUTTON_TEXT,
  Country,
  SUBTITLE1,
  SUBTITLE2,
  DISCLAIMER_TEXT,
} from '../../components/refer/constants'
import { validate } from '../../components/refer/formValidation'
import confetti from '../../components/refer/illustrations/confetti.png'
import mail from '../../components/refer/illustrations/mail.png'
import {
  Container,
  Heading,
  Subhead,
  Subtitle,
  Image,
  Form,
  Button,
  SelectInput,
  InputWithError,
  SectionContainer,
} from '../../components/refer/styled'
import { Disclaimer } from '../../components/refer/styled/Texts'
import {
  renderTextWithB,
  renderTextWithLineBreaks,
} from '../../components/utils'
import useForm from '../../hooks/useForm'
import useIntercomUserId from '../../hooks/useIntercomUserId'
import useQueryOrStorageParam from '../../hooks/useQueryOrStorageParam'

const ReferralPage = () => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const visitorId = useIntercomUserId()
  const referrerEmail = useQueryOrStorageParam('referrer_email', '', {
    persist: true,
  })
  const referrerName = useQueryOrStorageParam('referrer_name', '', {
    persist: true,
  })
  const initialVals = useMemo(
    () => ({
      name: '',
      email: '',
      country: 'US',
      referrerEmail,
      referrerName,
    }),
    [referrerName, referrerEmail],
  )
  const {
    values: formState,
    setValues: setFormState,
    errors,
    handleChange,
    runValidation,
    resetState: resetFormState,
  } = useForm(initialVals, validate)

  useEffect(() => {
    if (referrerEmail) {
      setFormState((formState) => ({ ...formState, referrerEmail }))
    }
    if (referrerName) {
      setFormState((formState) => ({ ...formState, referrerName }))
    }
  }, [referrerEmail, referrerName, setFormState])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      const isValid = runValidation()

      if (isValid) {
        await requestGraphql({
          operationName: 'Refer',
          variables: {
            input: {
              intercomUserId: visitorId,
              ...formState,
            },
          },
          query: `
          mutation Refer($input: ReferInput!) {
            refer(input: $input) {
              isSuccess
            }
          }
        `,
        })
          .then(console.info)
          .catch(console.error)

        setFormSubmitted(true)
      }
    },
    [runValidation, formState, visitorId],
  )

  const handleRefreshClick = useCallback(
    (e) => {
      e.preventDefault()
      resetFormState()
      setFormSubmitted(false)
    },
    [resetFormState, setFormSubmitted],
  )

  return (
    <Page
      head={{
        title: PAGE_TITLE,
        description: PAGE_DESCRIPTION,
      }}
    >
      <Container noPadding>
        {formSubmitted && formState.country === 'OTHER' ? null : (
          <>
            <Image
              src={formSubmitted ? confetti : mail}
              alt={formSubmitted ? IMAGE_ALT1 : IMAGE_ALT2}
            />
          </>
        )}
        <Heading>
          {!formSubmitted
            ? HEADLINE
            : formState.country === 'OTHER'
            ? SENT_ERROR_HEADLINE
            : SENT_HEADLINE}
        </Heading>
        <Subhead>
          {renderTextWithB(
            !formSubmitted
              ? SUBHEAD
              : formState.country === 'OTHER'
              ? SENT_ERROR_SUBHEAD
              : SENT_SUBHEAD,
          )}
        </Subhead>
        {!formSubmitted && (
          <Form onSubmit={handleSubmit}>
            <>
              {(!referrerName || !referrerEmail) && (
                <SectionContainer>
                  <Subtitle>{SUBTITLE1}</Subtitle>
                  <InputWithError
                    placeholder={'Your full name'}
                    name={'referrerName'}
                    value={formState.referrerName}
                    onChange={handleChange}
                    autoFocus
                    hasError={!!(errors && errors.referrerName)}
                    errors={errors}
                    required
                  />
                  <InputWithError
                    placeholder={'Your email address'}
                    name={'referrerEmail'}
                    value={formState.referrerEmail}
                    onChange={handleChange}
                    autoFocus
                    hasError={!!(errors && errors.referrerEmail)}
                    errors={errors}
                    required
                  />
                </SectionContainer>
              )}
              <Subtitle>{SUBTITLE2}</Subtitle>
              <InputWithError
                placeholder={'Full name'}
                name={'name'}
                value={formState.name}
                onChange={handleChange}
                autoFocus
                hasError={!!(errors && errors.name)}
                errors={errors}
                required
              />
              <InputWithError
                placeholder={'Email'}
                name={'email'}
                type={'email'}
                value={formState.email}
                onChange={handleChange}
                hasError={!!(errors && errors.email)}
                errors={errors}
                required
              />
              <SelectInput
                placeholder={'Country'}
                name={'country'}
                // eslint-disable-next-line
                options={Object.keys(Country).map((code) => ({
                  value: code,
                  // @ts-ignore
                  name: Country[code],
                }))}
                value={formState.country}
                onChange={handleChange}
              />
            </>
            <Button>{SEND_BUTTON_TEXT}</Button>
          </Form>
        )}
        {formSubmitted && (
          <Button onClick={handleRefreshClick}>{REFRESH_BUTTON_TEXT}</Button>
        )}
        {!formSubmitted && (
          <Disclaimer>{renderTextWithLineBreaks(DISCLAIMER_TEXT)}</Disclaimer>
        )}
      </Container>
    </Page>
  )
}

export default ReferralPage
