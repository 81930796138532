import type { ChangeEvent } from 'react'
import { useState, useCallback } from 'react'

type Errors<T extends Record<string, string>> = Partial<Record<keyof T, string>>

const useForm = <T extends Record<string, string>>(
  initialVals: T,
  validate: (vals: T) => Errors<T>,
) => {
  const [values, setValues] = useState(initialVals)
  const [errors, setErrors] = useState<Errors<T>>({})

  const runValidation = useCallback(() => {
    const potentialErrors = validate(values)
    setErrors(potentialErrors)
    return Object.keys(potentialErrors).length === 0
  }, [validate, values, setErrors])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      })
    },
    [values, setValues],
  )

  const resetState = useCallback(() => {
    setValues(initialVals)
    setErrors({})
  }, [initialVals, setValues, setErrors])

  return {
    values,
    setValues,
    errors,
    handleChange,
    runValidation,
    resetState,
  }
}

export default useForm
