export const PAGE_TITLE = 'Give the gift of Double'
export const PAGE_DESCRIPTION = 'Give the gift of Double'

export const HEADLINE = 'Give the gift of Double'
export const SUBHEAD =
  'When your friend onboards with Double you receive **3 to 18 hours for free**, while **they get up to $1000 discount** for their first month.*'

export const SENT_HEADLINE = 'Thank you!'
export const SENT_SUBHEAD =
  'Thanks for letting your network know about Double! We’ll let you know when your friend onboards with Double.'
export const SENT_ERROR_HEADLINE =
  'Double is currently available in the US, Canada, and France only.'
export const SENT_ERROR_SUBHEAD =
  "As soon as we expand to other countries, we'll let you know!"

export const IMAGE_ALT1 = 'Referral Double'
export const IMAGE_ALT2 = 'Email Sent'

export const SUBTITLE1 = 'What’s your name and email?'
export const SUBTITLE2 = 'Refer someone in your network'

export const SEND_BUTTON_TEXT = 'Submit'
export const REFRESH_BUTTON_TEXT = 'Refer someone else'

export const DISCLAIMER_TEXT =
  '* The bonus varies by plan chosen by your friend:\nStarter – 3 hours free for you, $150 off for them for the first month;\nBuild – 7 hours free for you, $400 off for them;\nScale – 12 hours free for you, $700 off for them;\nEnterprise – 18 hours free for you, $1000 off for them.'

export enum Country {
  US = 'United States of America',
  CA = 'Canada',
  FR = 'France',
  OTHER = 'Other',
}
