interface Values {
  email?: string
  country?: string
  name?: string
  referrerEmail?: string
  referrerName?: string
}

export const validate = (values: Values): Values => {
  const errors: Values = {}

  if (!values.email) {
    errors.email = 'Email Address is required'
  }

  if (!values.country) {
    errors.country = 'Country is required'
  }

  if (!values.name) {
    errors.name = 'Full Name is required'
  }

  if (!values.referrerEmail) {
    errors.referrerEmail = 'Your email address is required'
  }

  if (!values.referrerName) {
    errors.referrerName = 'Your name is required'
  }

  return errors
}
